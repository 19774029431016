.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.container.container--mood {
  min-height: 466px;
}
.section--multimood .multimood {
  float: left;
  width: calc(100% - 500px);
  position: absolute;
  right: 0;
}
.section--multimood .content {
  float: left;
  position: relative;
  width: 500px;
  padding: 100px 100px;
}
.section--two .content {
  width: 102.734375%;
  margin-left: -1.3671875%;
}
.column {
  float: left;
  position: relative;
  margin-left: 1.33079848%;
  margin-right: 1.33079848%;
}
.column.column--left {
  width: 30.60836502%;
}
.column.column--right {
  width: 62.83269962%;
}
.footarea {
  width: 102.734375%;
  margin-left: -1.3671875%;
  display: flex;
  flex-wrap: wrap;
}
.footpart {
  margin-left: 1.33079848%;
  margin-right: 1.33079848%;
}
.footpart.footpart--slim {
  width: 22.33840304%;
}
.section--one .area {
  width: 100%;
}
.section--one .area > .unit {
  margin-right: 1.33079848%;
  margin-left: 1.33079848%;
  width: 97.33840304%;
}
.section--one .area .cb-album .body,
.section--one .area .head,
.section--one .area .foot,
.section--one .area .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--one .area > .slim {
  width: 47.33840304%;
}
.section--one .area > .slim .head,
.section--one .area > .slim .foot,
.section--one .area > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--one .area > .slim.cb-album .body {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--one .area > .slim .tiny {
  width: 100%;
}
.section--two .south {
  width: 100%;
}
.section--two .south > .unit {
  margin-right: 1.98863636%;
  margin-left: 1.98863636%;
  width: 96.02272727%;
}
.section--two .south .cb-album .body,
.section--two .south .head,
.section--two .south .foot,
.section--two .south .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--two .south > .slim {
  width: 46.02272727%;
}
.section--two .south > .slim .head,
.section--two .south > .slim .foot,
.section--two .south > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--two .south > .slim.cb-album .body {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--two .south > .slim .tiny {
  width: 100%;
}
.section--one .area {
  width: 102.734375%;
  margin-left: -1.3671875%;
  margin-top: 60px;
  margin-bottom: 60px;
}
@media (max-width: 1023px) {
  .section--one .area {
    margin-top: 60px;
  }
}
@media (max-width: 767px) {
  .section--one .area {
    margin-top: 60px;
  }
}
@media (max-width: 1023px) {
  .section--one .area {
    margin-bottom: 60px;
  }
}
@media (max-width: 767px) {
  .section--one .area {
    margin-bottom: 60px;
  }
}
.section--two .south {
  width: 104.14201183%;
  margin-left: -2.07100592%;
}
.section--two .area {
  margin-top: 60px;
  margin-bottom: 60px;
}
@media (max-width: 1023px) {
  .section--two .area {
    margin-top: 60px;
  }
}
@media (max-width: 767px) {
  .section--two .area {
    margin-top: 60px;
  }
}
@media (max-width: 1023px) {
  .section--two .area {
    margin-bottom: 60px;
  }
}
@media (max-width: 767px) {
  .section--two .area {
    margin-bottom: 60px;
  }
}
.section--one .area .wide.pure {
  padding-right: 33.26996198%;
  box-sizing: border-box;
}
.section--one .area .seam.slim {
  width: 30.60836502%;
}
.section--one .area .flat.slim {
  width: 22.33840304%;
}
.section--one .area .flat.slim .part.text.tiny {
  width: 61.70212766%;
  float: right;
}
.section--one .area .flat.slim .part.pict.tiny {
  width: 29.78723404%;
}
.section--one .area .edge.slim .part.text.tiny {
  width: 77.51004016%;
  float: right;
}
.section--one .area .edge.slim .part.pict.tiny {
  margin-left: 4.01606426%;
  width: 11.24497992%;
}
.section--two .area .flat.slim .part.text.tiny {
  width: 72.5308642%;
  float: right;
}
.section--two .area .flat.slim .part.pict.tiny {
  width: 21.60493827%;
}
/*# sourceMappingURL=./screen-large.css.map */